@use '/styles/mixin/fonts.module.scss' as *;

.drive-form-details {
  &__details-form {
    @apply mt-8;
    @apply rounded-2xl;
    @apply p-6;
    @apply pb-8;
    background-color: rgba(255, 255, 255, 0.5);
    @apply w-full;
    @apply flex;
    @apply justify-between;
    @apply flex-wrap;

    @screen md {
      @apply pb-6;
    }

    &__name-email {
      @apply w-full;

      @screen md {
        @apply w-[210px];
      }
    }

    &__phone-postcode {
      @apply flex;
      @apply w-full;
      @apply justify-between;

      @screen md {
        @apply w-[130px];
        @apply flex-col;
        @apply justify-start;
      }

      div:nth-child(1) {
        @apply w-[65%];
        @apply mr-1;

        @screen md {
          @apply w-full;
          @apply mr-0;
        }
      }

      div:nth-child(2) {
        @apply w-[32%];

        @screen md {
          @apply w-full;
        }
      }
    }

    &__message {
      @apply w-full;
      @screen md {
        @apply w-[210px];
      }
      @screen lg {
        @apply w-[256px];
      }
    }
  }

  &__field {
    @apply w-full;

    h2 {
      @apply m-0;
      @apply text-blue-dark;
      @include font-text('desktop', 'small');
      line-height: 15px;
    }

    p {
      @apply mt-1.5;
      @apply mb-[18px];
      @include font-text('desktop', 'small');
      @apply max-h-[80px];
      @apply overflow-y-auto;
    }

    p::-webkit-scrollbar {
      @apply w-2; /* Width of the scrollbar */
    }

    /* Styling the scrollbar (the draggable part) */
    p::-webkit-scrollbar-thumb {
      @apply bg-gray-400;
      @apply rounded-lg;
    }

    /* Styling the scrollbar track (the background) */
    p::-webkit-scrollbar-track {
      @apply bg-transparent;
    }
  }
}
