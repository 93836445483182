@use '/styles/mixin/fonts.module.scss' as *;
//main wrapper, contains error message and input container
.drive-form-animated-input-wrapper {
  @apply w-full;
  [class='error'] {
    p {
      @include font-label('mobile');
      @apply leading-[10px];
      @apply font-normal;
      @apply text-coral-dark;
      @apply text-right;
    }
  }
}

//render outline, container of prefix and input
.drive-form-animated-input-container {
  @apply w-full;
  @apply flex;
  // outline is used rather than border to avoid being covered by inner elements
  @apply border-none;
  @apply rounded-lg;
  @apply outline-transparent;
  @apply outline-2;
  outline-style: solid;
  @apply overflow-hidden;

  &[class~='error']:not([class~='disabled']) {
    @apply outline-coral-light;
    :global(.error-icon) {
      @apply cursor-text;
    }
  }
  &[class~='disabled'] {
    @apply select-none;
    @apply pointer-events-none;
    @apply outline-transparent;
    @apply text-gray-400;
    @apply opacity-80;
  }
  &:not([class~='disabled']) {
    &:hover {
      @apply outline-[#C6E1FF];
    }
    &:focus,
    &:focus-within,
    &:active {
      @apply outline-blue-light;
      @apply text-gray-600;
    }
  }
}

//contains label, actual html input and suffix icons
.drive-form-animated-input {
  --input-padding-right: 20px;
  @apply bg-white;
  @apply relative;
  @apply flex;
  @apply flex-col;
  @apply w-full;
  @apply rounded-lg;
  &--fade {
    @apply relative;
    // only for text input
    &::after {
      content: '';
      @apply absolute;
      @apply top-6;
      @apply bg-white;
      @apply w-4;
      @apply h-5;
      @apply z-4;
      right: var(--input-padding-right);
      background: linear-gradient(90deg, #ffffff99 5%, white 80%);
    }
  }
  &--textarea {
    @apply h-full;
  }
  //the text. not label tag
  &__label {
    @include font-label('desktop');
    @apply rounded-lg;
    @apply z-4;
    @apply select-none;
    @apply ml-3.5;
    @apply absolute;
    @apply top-0;
    @apply ease-in-out;
    @apply duration-150;
    transition-property: font-size top padding;
    @apply text-gray-400;
    @apply pt-2;
    @apply font-normal;
    &--textarea {
      @apply pt-3;
    }
    &--text-placeholder {
      @apply text-gray-500;
      @include font-text('desktop', 'medium');
      @apply top-1/2;
      top: calc(50% - 12px); //no using translated to avoid visual transition jump.
      @apply pt-0;
    }
    &--textarea-placeholder {
      @apply text-gray-500;
      @include font-text('desktop', 'medium');
      @apply top-6;
      @apply py-0;
      @apply leading-4;
    }

    &[class~='disabled'] {
      @apply text-gray-400;
      @apply opacity-80;
    }
  }

  &__input {
    @apply outline-none;
    @apply border-none;
    @include font-text('desktop', 'medium');
    @apply pt-2;
    @apply pl-[14px];
    padding-right: var(--input-padding-right);
    @apply h-14;
    @apply text-gray-500;
    &--textarea {
      @apply pt-7;
      @apply h-full;
      @apply resize-none;
    }
  }
  [class~='suffix-icon'] {
    @apply absolute;
    @apply top-1/2;
    @apply right-0;
    @apply -translate-y-1/2;
    @apply z-10;
  }
}
